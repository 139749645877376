import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import Spinner from 'components/common/Spinner/Spinner';
import useCurrentUser from 'hooks/useCurrentUser';
import CustomModal from 'components/common/CustomModal/CustomModal';
import JoinButton from './JoinButton';

import { getUserEvents, deleteEvent, addEvent, updateEvent } from 'api/Event/eventApi';
import { getID } from 'utils/getID';
import { findLabelById } from 'utils/findLabel';
import { ROLES } from 'Env';

import 'moment/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import './Scheduler.css';
import { addMessage } from 'api/Message/messageApi';
import { addConversation, getConversation } from 'api/Conversation/conversationApi';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

moment.locale('fr');

const Scheduler = ({ selectedGroup = null, groupTypes = [], studentDetails = {} }) => {
  const { user, loading: userLoading } = useCurrentUser();

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentView, setCurrentView] = useState('week');

  const shouldShowJoinButton = useCallback((event) => {
    const now = moment();
    const start = moment(event.start);
    const end = moment(event.end);
    return (
      (start.diff(now, 'hours') <= 24 && start.diff(now, 'hours') >= 0) ||
      (now.diff(end, 'hours') <= 24 && now.isAfter(start))
    );
  }, []);

  useEffect(() => {
    if (!user) return;

    const loadUserEvents = async () => {
      try {
        const data = await getUserEvents(user);

        const formattedEvents = data.map((event) => {
          // Obtenir l'heure UTC et la convertir en tant qu'heure locale
          const start = new Date(event.start);
          const end = new Date(event.end);

          // Forcer les heures UTC à s'afficher sans décalage dans le calendrier
          const adjustedStart = new Date(
            start.getUTCFullYear(),
            start.getUTCMonth(),
            start.getUTCDate(),
            start.getUTCHours(),
            start.getUTCMinutes(),
            start.getUTCSeconds()
          );

          const adjustedEnd = new Date(
            end.getUTCFullYear(),
            end.getUTCMonth(),
            end.getUTCDate(),
            end.getUTCHours(),
            end.getUTCMinutes(),
            end.getUTCSeconds()
          );

          return {
            ...event,
            start: adjustedStart,
            end: adjustedEnd,
            id: getID(event)
          };
        });

        setEvents(formattedEvents);
      } catch (error) {
        console.error('Error loading user events:', error);
      }
    };

    loadUserEvents();
  }, [user]);

  const handleDeleteEvent = useCallback((eventId) => {
    deleteEvent(eventId)
      .then(() => {
        setEvents((currentEvents) => currentEvents.filter((event) => event.id !== eventId));
      })
      .catch((error) => console.error('Error deleting event:', error));
  }, []);

  const handleSelectSlot = useCallback(
    async ({ start, end }) => {
      if (!selectedGroup || currentView === 'month') return;

      const groupType = findLabelById(groupTypes, selectedGroup.type).label;
      const title = `Groupe ${groupType} - ${studentDetails[selectedGroup.student_ids[0]]}`;

      if (title) {
        console.log('selectedGroup', selectedGroup);

        // Convertir les dates locales en UTC avant d'ajouter l'événement
        const utcStart = new Date(
          Date.UTC(
            start.getFullYear(),
            start.getMonth(),
            start.getDate(),
            start.getHours(),
            start.getMinutes(),
            start.getSeconds()
          )
        );

        const utcEnd = new Date(
          Date.UTC(
            end.getFullYear(),
            end.getMonth(),
            end.getDate(),
            end.getHours(),
            end.getMinutes(),
            end.getSeconds()
          )
        );

        const newEvent = {
          start: utcStart,
          end: utcEnd,
          title,
          recurring: false,
          recurringPattern: {},
          exceptions: [],
          users: [selectedGroup?.teacher_id || null, ...(selectedGroup?.student_ids || [])],
          group: selectedGroup ? getID(selectedGroup) : null,
          createdBy: getID(user),
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };

        addEvent(newEvent)
          .then((data) => {
            setEvents((currentEvents) => [...currentEvents, { ...newEvent, id: getID(data) }]);
          })
          .catch((error) => console.error('Error adding event:', error));
      }
    },
    [selectedGroup, currentView, groupTypes, studentDetails, user]
  );

  const saveEventToDB = (event, updates) => {
    // Convertir les dates en UTC pour le stockage dans la base de données
    const utcStart = new Date(
      Date.UTC(
        updates.start.getFullYear(),
        updates.start.getMonth(),
        updates.start.getDate(),
        updates.start.getHours(),
        updates.start.getMinutes(),
        updates.start.getSeconds()
      )
    );

    const utcEnd = new Date(
      Date.UTC(
        updates.end.getFullYear(),
        updates.end.getMonth(),
        updates.end.getDate(),
        updates.end.getHours(),
        updates.end.getMinutes(),
        updates.end.getSeconds()
      )
    );

    // Appeler l'API avec les dates en UTC
    const updatedEvent = {
      ...event,
      start: utcStart.toISOString(),
      end: utcEnd.toISOString()
    };

    return updateEvent(event.id, updatedEvent);
  };

  const sendMessageAfterEventUpdate = async (event, updates) => {
    try {
      // Construction du message
      let message = '';
      if (user.role === ROLES.TEACHER) {
        message = `Le professeur a modifié l'événement du ${moment(event.start).format(
          'DD/MM/YYYY'
        )} de ${moment(event.start).format('HH:mm')} à ${moment(event.end).format(
          'HH:mm'
        )} au ${moment(updates.start).format('DD/MM/YYYY')} de ${moment(updates.start).format(
          'HH:mm'
        )} à ${moment(updates.end).format('HH:mm')}.`;
      } else if (user.role === ROLES.STUDENT) {
        message = `L'étudiant a modifié l'événement du ${moment(event.start).format(
          'DD/MM/YYYY'
        )} de ${moment(event.start).format('HH:mm')} à ${moment(event.end).format(
          'HH:mm'
        )} au ${moment(updates.start).format('DD/MM/YYYY')} de ${moment(updates.start).format(
          'HH:mm'
        )} à ${moment(updates.end).format('HH:mm')}.`;
      }

      // Envoyer le message dans le groupe si l'événement est lié à un groupe
      if (event.group) {
        await addMessage(user, event.group, message);
        return;
      }

      // Création de la conversation appropriée si elle n'existe pas
      let roomID;
      if (user.role === ROLES.TEACHER) {
        const otherUserID = event.users.filter((id) => id !== getID(user))[0];
        roomID = await getConversation(getID(user), otherUserID);
        console.log('Room ID (Teacher):', roomID);

        if (!roomID) {
          const conversation = await addConversation(getID(user), [otherUserID]);
          console.log('New Conversation Created (Teacher):', conversation);
          roomID = conversation._id;
        }
      } else if (user.role === ROLES.STUDENT) {
        roomID = await getConversation(getID(event.teacherId), getID(user));
        console.log('Room ID (Student):', roomID);

        if (!roomID) {
          const conversation = await addConversation(getID(event.teacherId), [getID(user)]);
          console.log('New Conversation Created (Student):', conversation);
          roomID = conversation._id;
        }
      }

      // Envoi du message dans la conversation appropriée
      if (roomID) {
        const sentMessage = await addMessage(user, roomID, message);
        console.log('Message sent:', sentMessage);
      }
    } catch (error) {
      console.error('Error sending message after event update:', error);
    }
  };

  const handleEventUpdate = useCallback((event, updates) => {
    // Sauvegarder les dates converties en UTC dans la base de données
    saveEventToDB(event, updates)
      .then(() => {
        setEvents((prevEvents) =>
          prevEvents.map((evt) => (evt.id === event.id ? { ...evt, ...updates } : evt))
        );
      })
      .catch((error) => console.error('Error updating event:', error));

    sendMessageAfterEventUpdate(event, updates);
  }, []);

  const eventPropGetter = useCallback(
    () => ({
      style: {
        backgroundColor: '#EFF4FA',
        color: '#3A74BB',
        fontWeight: '500',
        fontFamily: 'Quadran',
        fontSize: '12px',
        border: 'none'
      }
    }),
    []
  );

  const calendarMessages = useMemo(
    () => ({
      today: "Aujourd'hui",
      previous: '<',
      next: '>',
      month: 'Mois',
      week: 'Semaine',
      day: 'Jour',
      agenda: 'Agenda',
      date: 'Date',
      time: 'Heure',
      event: 'Événement'
    }),
    []
  );

  if (userLoading) return <Spinner />;

  return (
    <div>
      <CustomModal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        title="Supprimer un cours"
        isPrompt={false}
        submitButton={{
          variant: 'danger',
          text: 'Valider',
          submit: () => {
            handleDeleteEvent(selectedEvent?.id);
            setDeleteModal(false);
          }
        }}
      >
        <span>Attention, cette action est irréversible! </span>
      </CustomModal>

      {selectedEvent && (
        <CustomModal
          show={showModal}
          onHide={() => setShowModal(false)}
          title={`${selectedEvent?.title}`}
          isPrompt={false}
          submitButton={
            getID(user) === selectedEvent?.createdBy
              ? {
                  variant: 'danger',
                  text: 'Supprimer',
                  submit: () => {
                    setShowModal(false);
                    setDeleteModal(true);
                  }
                }
              : {
                  variant: 'primary',
                  text: 'Valider',
                  submit: () => {
                    setShowModal(false);
                  }
                }
          }
        >
          {selectedEvent.level && (
            <p>
              <strong>Niveau: </strong>
              {Array.isArray(selectedEvent?.level)
                ? selectedEvent?.level[0]?.label
                : selectedEvent?.level?.label}
            </p>
          )}
          {selectedEvent.subject && (
            <p>
              <strong>Matière: </strong>
              {Array.isArray(selectedEvent?.subject)
                ? selectedEvent?.subject[0]?.label
                : selectedEvent?.subject?.label}
            </p>
          )}

          {user.role === ROLES.TEACHER && shouldShowJoinButton(selectedEvent) ? (
            <JoinButton selectedEvent={selectedEvent} selectedGroup={selectedGroup} />
          ) : (
            <div>Vous ne pouvez pas encore rejoindre le cours.</div>
          )}
          {user.role === ROLES.STUDENT &&
            `Vous recevrez une notification lorsque le professeur lancera l'appel.`}
        </CustomModal>
      )}

      <DnDCalendar
        localizer={localizer}
        events={events}
        onEventDrop={({ event, start, end }) => handleEventUpdate(event, { start, end })}
        resizable
        onEventResize={({ event, start, end }) => handleEventUpdate(event, { start, end })}
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={(event) => {
          setSelectedEvent(event);
          setShowModal(true);
        }}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700 }}
        draggableAccessor={() => true}
        messages={calendarMessages}
        defaultView="week"
        views={['month', 'week', 'day']}
        min={new Date(0, 0, 0, 8, 0, 0)}
        max={new Date(0, 0, 0, 23, 0, 0)}
        eventPropGetter={eventPropGetter}
        onView={setCurrentView}
      />
    </div>
  );
};

Scheduler.propTypes = {
  selectedGroup: PropTypes.object,
  groupTypes: PropTypes.array,
  studentDetails: PropTypes.object
};

export default Scheduler;
