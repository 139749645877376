import React, { useState } from 'react';

import heroPlanets from 'assets/hero_planets.svg';
import emailPlanets from 'assets/email_planets.svg';
import emailPlanets1 from 'assets/planet_email_1.svg';
import emailPlanets2 from 'assets/planet_email_2.svg';

import { Row, Col, Card, Image } from 'react-bootstrap';

import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

import './BlueBand.css';

const BlueBand = () => {
  const [userPhoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(null);
  const [numberSent, setNumberSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userPhoneNumber.match(/^\+?[0-9]\d{1,14}$/)) {
      setError(userPhoneNumber);
      return;
    }

    const requestOptions = {
      method: 'POST',

      body: JSON.stringify({
        phone: userPhoneNumber
      })
    };

    fetchWithAuthorization(API_URL + '/api/email/send-phone', requestOptions)
      .then((response) => {
        if (response.ok) {
          setNumberSent(true);
        } else {
          console.error('Numéro non envoyé');
        }
      })
      .catch((error) => {
        console.error('Erreur:', error);
      });
  };

  return (
    <Card className="bb-EmailCard text-center pb-md-3">
      <Image src={heroPlanets} className="card-img-top d-md-none pe-none" />
      <Card.Body>
        <Row>
          <Col md={1} className="d-none d-md-flex">
            <Image src={emailPlanets1} className="card-img-top pe-none" />
          </Col>
          <Col xs={12} md={10}>
            <Row className="px-4">
              <Col xs={12} className="p-3 py-4">
                <h2 className="H2 text-white">Boostez vos chances de réussite</h2>
              </Col>
              <Col xs={12} className="p-3">
                <Card.Text>
                  Nos professeurs expérimentés vous accompagnent pour maximiser votre potentiel et
                  atteindre vos objectifs. Profitez d'un suivi personnalisé et d'une méthode de
                  travail efficace, adaptée à vos besoins !
                </Card.Text>
              </Col>
              <Col xs={12} className="p-3">
                <Row className="justify-content-center p-0">
                  <Col xs={12}>
                    <Row className="justify-content-center">
                      <Col
                        xs={12}
                        md={8}
                        className="d-flex align-self-center align-items-center justify-content-center ps-3"
                        onClick={focus}
                      >
                        {numberSent ? (
                          <span className="bb-NumberSent">Numéro envoyé</span>
                        ) : (
                          <form className="w-100" onSubmit={handleSubmit}>
                            <input
                              type="text"
                              id="phoneNotification"
                              aria-describedby="phoneNotification"
                              placeholder="Numéro de téléphone"
                              value={userPhoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                            {error && <span>{error}</span>}
                            <button>Être recontacté</button>
                          </form>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={1} className="d-none d-md-flex">
            <Image src={emailPlanets2} className="card-img-top pe-none" />
          </Col>
        </Row>
      </Card.Body>
      <Image src={emailPlanets} className="card-img-top d-md-none pe-none" />
    </Card>
  );
};

export default BlueBand;
