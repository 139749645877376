import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import star from 'assets/Ystar.svg';
import './TeacherPersona.css';

const TeacherPersona = ({ name, imgUrl, description, subject, note = '4.9/5', chipset = [] }) => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column w-100">
      <Image src={imgUrl} alt="image prof" rounded fluid className="hec-persona-teacher-img" />
      <div className="d-flex justify-content-center align-items-center mb-1">
        <span className="hec-persona-name"> {name} </span>
        <Image src={star} className="hec-persona-star" />
        <span className="hec-persona-note">{note}</span>
      </div>
      <div className="hec-persona-classtype">{subject}</div>
      <div className="hec-persona-description">{description}</div>
      <div className="hec-persona-chipset">
        {chipset.map((chip, index) => (
          <span key={index} className="hec-persona-chip">
            {chip}
          </span>
        ))}
      </div>
    </div>
  );
};

TeacherPersona.propTypes = {
  name: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  chipset: PropTypes.array
};

export default TeacherPersona;
