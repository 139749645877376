/* eslint-disable no-unused-vars */
import React from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import './InscriptionHEC.css';
import { Col, Row } from 'react-bootstrap';
import Presentation from './HecHomePageParts/Presentation';
import CarouselSliderTeacher from './HecCarouselSlider/CarouselSliderTeacher';
import CarouselSliderStudent from './HecCarouselSlider/CarouselSliderStudent';
import CardOverlay from './PricingCardsHec/CardOverlay';
import FastPhoneInput from 'components/common/PhoneInput/FastPhoneInput';
import BlueBand from './BlueBand';

const InscriptionHEC = () => {
  const user = useCurrentUser().user;

  return (
    <div className="inscription-hec-container mt-5 ">
      <Row className="d-flex justify-content-center mb-5">
        <Col xs={10} md={10} lg={10} xl={12} className="justify-content-center">
          <Presentation />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mb-5 w-100 mt-5">
        <Col xs={10} md={10} lg={10} xl={10}>
          <CarouselSliderTeacher />
        </Col>
      </Row>
      <CardOverlay />
      <Row className="d-flex justify-content-center mb-5 w-100 mt-5">
        <Col xs={10} md={10} lg={10} xl={10}>
          <CarouselSliderStudent />
        </Col>
      </Row>
      <BlueBand />
    </div>
  );
};

export default InscriptionHEC;
