import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import ProfHecImage from 'assets/mytest.svg';
import './Presentation.css';
import FastPhoneInput from 'components/common/PhoneInput/FastPhoneInput';
import star from 'assets/Ystar.svg';
import hat from 'assets/hat.svg';
import validate from 'assets/validate.svg';

const Presentation = () => {
  // Fonction pour gérer le défilement
  const scrollToOffers = () => {
    const offersSection = document.getElementById('offer-card-overlay-section');
    if (offersSection) {
      offersSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Row className="presentation-wrapper">
      <Col xs={12} md={10} lg={10} xl={5} className="presentation-col-wrapper">
        <h2 className="presentation-title mb-5">Vise l’excellence avec la prépa Mona</h2>
        <p className="presentation-text mb-5">
          Préparez-vous aux concours des grandes écoles de commerce avec notre formation
          d'excellence à prix abordables, où que vous soyez !
        </p>
        <div className="d-flex justify-content-center mb-5">
          <div className="presentation-phone-input-wrapper">
            <FastPhoneInput ButtonColor="#FBCE5B" ButtonTextColor="#000000" />
          </div>
          <span onClick={scrollToOffers} className="ms-2 vew-offer">
            Voir nos offres
          </span>
        </div>
        <Row id="hec-information-container-presentation">
          <Col className="d-flex">
            <div className="d-flex flex-column mt-4 mb-4">
              <Image src={star} alt="star" width="24" height="24" />
              <div>4.8/5</div>
              note moyenne
            </div>
          </Col>
          <Col className="d-flex">
            <div className="d-flex flex-column">
              <Image src={hat} alt="star" width="24" height="24" />
              Professeurs de qualité
            </div>
          </Col>
          <Col className="d-flex">
            <div className="d-flex flex-column">
              <Image src={validate} alt="star" width="24" height="24" />
              Élèves satisfaits et admis
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={12} md={10} lg={10} xl={5} className="d-none d-xl-flex justify-content-center mt-3">
        <div id="image-prof-hec">
          <Image src={ProfHecImage} width="100%" height="100%" alt="prof image" />
        </div>
      </Col>
    </Row>
  );
};

export default Presentation;
