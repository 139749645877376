import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

export function addConversation(teacherID, studentIDs, groupID = null) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({
      teacher_id: teacherID,
      student_ids: studentIDs,
      group_id: groupID
    })
  };

  return fetchWithAuthorization(`${API_URL}/api/conversation`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      console.log('fetch here => ', data);
      return data;
    });
}

export function getConversationByID(conversationID) {
  const requestOptions = {
    method: 'GET'
  };
  return fetchWithAuthorization(`${API_URL}/api/conversation/${conversationID}`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function getConversation(teacherID, studentID) {
  const requestOptions = {
    method: 'GET'
  };

  return fetchWithAuthorization(
    `${API_URL}/api/conversation/?teacher_id=${teacherID}&student_id=${studentID}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      if (data.length > 0) {
        const conversation = data.find(
          (item) => item.teacher_id === teacherID && item.student_ids.includes(studentID)
        );
        return conversation ? conversation._id : null;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
}

export function getConversations() {
  const requestOptions = {
    method: 'GET'
  };
  return fetchWithAuthorization(`${API_URL}/api/conversation`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}
