import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';
import { getID } from 'utils/getID';

export const getEventByID = async (eventID) => {
  const response = await fetchWithAuthorization(`${API_URL}/api/event/${eventID}`);
  return response.json();
};

export const getUserEvents = async (user) => {
  const response = await fetchWithAuthorization(`${API_URL}/api/event/user/${getID(user)}`);
  return response.json();
};

export const getGroupData = async (groupId) => {
  const response = await fetchWithAuthorization(`${API_URL}/api/group/${groupId}`);
  return response.json();
};

export const deleteEvent = async (eventId) => {
  const response = await fetchWithAuthorization(`${API_URL}/api/event/${eventId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response.json();
};

export const addEvent = async (newEvent) => {
  const response = await fetchWithAuthorization(`${API_URL}/api/event`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(newEvent)
  });
  return response.json();
};

export const updateEvent = async (eventId, updatedEvent) => {
  const response = await fetchWithAuthorization(`${API_URL}/api/event/${eventId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(updatedEvent)
  });
  return response.json();
};

export const getUserClosestEvent = async (user) => {
  try {
    const events = await getUserEvents(user);

    if (!events || events.length === 0) {
      return null; // Retourne null s'il n'y a pas d'événements
    }

    const currentDate = new Date();

    // Trouver l'événement le plus proche de la date actuelle qui est dans le futur
    const closestEvent = events.reduce((closest, current) => {
      const currentStartDate = new Date(current.start);
      const closestStartDate = new Date(closest.start);

      // Ne considérer que les événements futurs
      if (currentStartDate <= currentDate) {
        return closest;
      }

      return Math.abs(currentStartDate - currentDate) < Math.abs(closestStartDate - currentDate)
        ? current
        : closest;
    });

    // Vérifier si l'événement trouvé est dans le futur
    return closestEvent && new Date(closestEvent.start) > currentDate ? closestEvent : null;
  } catch (error) {
    console.error('Error fetching closest event:', error);
    throw error;
  }
};
