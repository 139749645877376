/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { getUserClosestEvent } from 'api/Event/eventApi';
import PropTypes from 'prop-types';
import { Col, Container, Row, Button, Image } from 'react-bootstrap';
import './CommingClassBand.css';
import { getTeacherByID } from 'utils/getTeacherByID';
import { useProfilePicture } from 'components/features/ProfilePicture/ProfilePicture';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';
import { API_URL, FILE_TYPES } from 'Env';
import avatarDefault from 'assets/avatar_default.png';

const CommingClassBand = ({ user }) => {
  const [closestEvent, setClosestEvent] = useState(null);
  const [error, setError] = useState(null);
  const [teacherName, setTeacherName] = useState(null);
  const [imageSrc, setImageSrc] = useState(avatarDefault);

  useEffect(() => {
    const fetchClosestEvent = async () => {
      try {
        const event = await getUserClosestEvent(user);
        setClosestEvent(event);
        const teacher = await getTeacherByID(event.teacherId);
        setTeacherName(teacher.firstName + ' ' + teacher.lastName);
      } catch (error) {
        console.error('Error fetching closest event:', error);
        setError('Failed to fetch closest event');
      }
    };
    const fetchProfileImage = async () => {
      try {
        const response = await fetchWithAuthorization(
          `${API_URL}/api/files/download/${FILE_TYPES.PROFILE}/profilePicture.webp?userId=${closestEvent.teacherId}`
        );

        if (response.ok) {
          const imageBlob = await response.blob();
          const imageUrl = URL.createObjectURL(imageBlob);
          setImageSrc(imageUrl);
        } else {
          console.error('Image not found, using default avatar.');
        }
      } catch (error) {
        console.error('An error occurred while fetching the profile image:', error);
      }
    };

    fetchClosestEvent();
    fetchProfileImage();
  }, [user]);

  if (!closestEvent || error) {
    return null;
  }

  const eventDate = new Date(closestEvent.start);
  const formattedDate = eventDate.toLocaleDateString('fr-FR', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });

  const formattedTime = closestEvent.start.slice(11, 16);
  const formattedDateTime = `${formattedDate} | ${formattedTime}`;
  const courseType = closestEvent.group ? 'Cours de groupe' : 'Cours individuel';

  return (
    <Container id="comming-class-band-wrapper">
      <Row id="comming-class-band-inside-container">
        <Col className="comming-class-band-inside-prof-container">
          <div className="title">Mon prochain cours</div>
          <div className="date">{formattedDateTime}</div>
          <div className="course-type">
            {closestEvent.subject.label} {courseType}
          </div>
          <Button className="btn-start-course" onClick={() => console.log('Démarrer le cours')}>
            Démarrer le cours
          </Button>
        </Col>
        <Col id="comming-class-band-inside-prof-container">
          <div className="teacher-info">
            <span>avec</span>
            <Image src={imageSrc} alt="Teacher Avatar" roundedCircle width={40} height={40} />
            <span>{teacherName}</span>
          </div>
          <div className="cancel-link" onClick={() => console.log('Annuler mon cours')}>
            Annuler mon cours
          </div>
        </Col>
      </Row>
    </Container>
  );
};

CommingClassBand.propTypes = {
  user: PropTypes.object.isRequired
};

export default CommingClassBand;
