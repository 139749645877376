import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { createUnconfirmedEvent } from 'api/UnconfirmedEvent/unconfirmedEventApi';
import { getID } from 'utils/getID';
import useCurrentUser from 'hooks/useCurrentUser';
import ProfilePicture from 'components/features/ProfilePicture/ProfilePicture';
import LevelsAndSubjects from 'components/common/Select/LevelsAndSubjects';
import Spinner from 'react-bootstrap/Spinner';

const PlanifDetails = ({
  show,
  handleClose,
  teacherName,
  teacher,
  handleNextStep,
  isTrial = true
}) => {
  const [formData, setFormData] = useState({
    requestedDate: '',
    requestedTime: '',
    duration: '',
    workFocus: '',
    level: null,
    subject: null
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const { user } = useCurrentUser();

  const handleChange = useCallback((e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
    setErrors((prevState) => ({ ...prevState, [id]: '' }));
  }, []);

  const handleLevelSubjectChange = useCallback((level, subject) => {
    setFormData((prevState) => ({
      ...prevState,
      level: level,
      subject: subject
    }));
    setErrors((prevState) => ({ ...prevState, level: '', subject: '' }));
  }, []);

  const validateForm = () => {
    let newErrors = {};

    if (!formData.requestedDate) newErrors.requestedDate = 'La date est obligatoire.';
    if (!formData.requestedTime) newErrors.requestedTime = "L'heure est obligatoire.";
    if (!isTrial && (!formData.duration || formData.duration <= 0)) {
      newErrors.duration = 'La durée doit être supérieure à 0 minutes.';
    }
    if (!isTrial && formData.duration < 30) {
      newErrors.duration = "La durée doit être d'au moins 30 minutes.";
    }
    if (!formData.level) newErrors.level = 'Le niveau est obligatoire.';
    if (!formData.subject) newErrors.subject = 'La matière est obligatoire.';
    if (!formData.workFocus) newErrors.workFocus = 'Ce champ est obligatoire.';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);
      return;
    }

    const { requestedDate, requestedTime, duration, level, subject, workFocus } = formData;

    try {
      const newRequest = {
        teacherId: getID(teacher),
        studentId: getID(user),
        requestedDate,
        requestedTime,
        trial: isTrial,
        duration: isTrial ? 45 : duration,
        level: level,
        subject: subject,
        workFocus
      };

      const response = await createUnconfirmedEvent(newRequest);

      if (response.message === 'Insufficient credits') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          form: "Vous n'avez pas assez de crédits pour planifier ce cours."
        }));
        setLoading(false);
        return;
      }

      handleNextStep();
      setLoading(false);
    } catch (error) {
      console.error('Error creating unconfirmed event:', error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        form: "Une erreur est survenue lors de la création de l'événement."
      }));
    } finally {
      setLoading(false);
    }
  }, [formData, teacher, user, handleNextStep, isTrial]);

  if (!show) return null;

  return (
    <Container fluid className="overlay">
      <Row className="d-flex justify-content-center w-75">
        <Col
          xl={7}
          lg={8}
          md={12}
          sm={12}
          xs={12}
          className="popup offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0 offset-1"
        >
          <ProfilePicture userId={getID(teacher)} />
          <h2 className="form1-title mt-1 mb-2">
            Planifier un cours Maintenant avec {teacherName}
          </h2>
          <div className="popup-body mb-5">
            {isTrial && (
              <p className="text-center">
                <span className="form1-lesson-duration">45 min</span>
                <span className="form1-lesson-price ms-4">4,99€</span>
              </p>
            )}
            <p className="fill-information ms-xl-5 mt-xl-4">2. Planifier un cours</p>
            <Row>
              <Col xs={11} xl={10} className="ms-xl-5">
                <label htmlFor="requestedTime" className="form-label">
                  Heure demandée
                </label>
                <input
                  id="requestedTime"
                  className="custom-form-input"
                  type="time"
                  value={formData.requestedTime}
                  onChange={handleChange}
                  style={{ height: '48px' }}
                  placeholder="Heure demandée"
                />
                {errors.requestedTime && <p style={{ color: 'red' }}>{errors.requestedTime}</p>}
              </Col>

              <Col xs={11} xl={10} className="ms-xl-5 mt-3">
                <label htmlFor="requestedDate" className="form-label">
                  Date demandée
                </label>
                <input
                  id="requestedDate"
                  className="custom-form-input"
                  type="date"
                  value={formData.requestedDate}
                  onChange={handleChange}
                  style={{ height: '48px' }}
                  placeholder="Date demandée"
                />
                {errors.requestedDate && <p style={{ color: 'red' }}>{errors.requestedDate}</p>}
              </Col>

              {!isTrial && (
                <Col xs={11} xl={10} className="ms-xl-5 mt-3">
                  <label htmlFor="duration" className="form-label">
                    Durée en minutes
                  </label>
                  <input
                    id="duration"
                    className="custom-form-input"
                    type="number"
                    value={formData.duration}
                    onChange={handleChange}
                    style={{ height: '48px' }}
                    min="0"
                    placeholder="Durée en minutes"
                  />
                  {errors.duration && <p style={{ color: 'red' }}>{errors.duration}</p>}
                </Col>
              )}

              <Col xs={11} xl={10} className="ms-xl-5 mt-3">
                <LevelsAndSubjects
                  defaultLevels={teacher.teacherDetails.levels}
                  defaultSubjects={teacher.teacherDetails.subjects}
                  isMulti={false}
                  selectedLevels={formData.level}
                  setSelectedLevels={(level) => handleLevelSubjectChange(level, formData.subject)}
                  selectedSubjects={formData.subject}
                  setSelectedSubjects={(subject) =>
                    handleLevelSubjectChange(formData.level, subject)
                  }
                />
                {errors.level && <p style={{ color: 'red' }}>{errors.level}</p>}
                {errors.subject && <p style={{ color: 'red' }}>{errors.subject}</p>}
              </Col>

              <Col xs={11} xl={10} className="ms-xl-5 mt-3">
                <label htmlFor="workFocus" className="form-label">
                  Sur quoi souhaitez-vous travailler ?
                </label>
                <textarea
                  id="workFocus"
                  className="custom-form-input"
                  value={formData.workFocus}
                  onChange={handleChange}
                  placeholder="Sur quoi souhaitez-vous travailler ?"
                />
                {errors.workFocus && <p style={{ color: 'red' }}>{errors.workFocus}</p>}
              </Col>
            </Row>
          </div>
          <div className="text-center">
            {errors.form && <p style={{ color: 'red', marginBottom: '10px' }}>{errors.form}</p>}
            {loading ? (
              <Spinner animation="border" variant="success" />
            ) : (
              <>
                <Button
                  onClick={handleSubmit}
                  className="btn-success col-xl-4 col-8 fw-semibold mb-3"
                >
                  Étape suivante
                </Button>
                <p style={{ cursor: 'pointer' }} className="cancel-button" onClick={handleClose}>
                  Annuler
                </p>
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

PlanifDetails.propTypes = {
  show: PropTypes.bool.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  teacher: PropTypes.object.isRequired,
  teacherName: PropTypes.string.isRequired,
  isTrial: PropTypes.bool
};

export default React.memo(PlanifDetails);
