import React, { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import CarouselSlider from './CarouselSlider';
import TeacherPersona from './TeacherPersona';
import { Col, Image } from 'react-bootstrap';
import './CarouselSliderTeacher.css';
import leftArrow from 'assets/letfArrow.png';
import rightArrow from 'assets/rightArrow.png';
import Hero1 from 'assets/teacherpersona1.svg';
import Hero2 from 'assets/teacherpersona2.svg';
import Hero3 from 'assets/teacherpersona3.svg';
import Hero4 from 'assets/teacherpersona4.svg';

const CarouselSliderTeacher = () => {
  const carouselRef = useRef(null);

  const persona = [
    {
      name: 'Mathieu',
      subject: 'Prof de mathématiques',
      description:
        'Prêt à dépasser vos limites académiques ? Rejoignez-moi pour des cours en ligne sur mesure !',
      imgUrl: Hero1,
      note: '4.3',
      chipset: ['HEC', 'Top 10 concours']
    },
    {
      name: 'Maëlle',
      subject: 'Prof d’économie',
      description:
        'Ensemble, nous allons construire un avenir éducatif brillant. Prêt à commencer ?',
      imgUrl: Hero2,
      note: '5.0'
    },
    {
      name: 'Violette',
      subject: 'Prof de mathématiques',
      description:
        "Des cours en ligne flexibles qui s'adaptent à votre rythme et à vos besoins éducatifs",
      imgUrl: Hero3,
      note: '4.8',
      chipset: ['Top 3 oraux']
    },
    {
      name: 'Sofiane',
      subject: 'Prof de français',
      description:
        "Découvrez une nouvelle façon d'apprendre. Ensemble, nous allons transformer vos défis en réussites académiques",
      imgUrl: Hero4,
      note: '4.8'
    },
    {
      name: 'Mathieu',
      subject: 'Prof de mathématiques',
      description:
        'Prêt à dépasser vos limites académiques ? Rejoignez-moi pour des cours en ligne sur mesure !',
      imgUrl: Hero1,
      note: '4.3',
      chipset: ['HEC', 'Top 10 concours']
    },
    {
      name: 'Maëlle',
      subject: 'Prof d’économie',
      description:
        'Ensemble, nous allons construire un avenir éducatif brillant. Prêt à commencer ?',
      imgUrl: Hero2,
      note: '5.0'
    },
    {
      name: 'Violette',
      subject: 'Prof de mathématiques',
      description:
        "Des cours en ligne flexibles qui s'adaptent à votre rythme et à vos besoins éducatifs",
      imgUrl: Hero3,
      note: '4.8',
      chipset: ['Top 3 oraux']
    },
    {
      name: 'Sofiane',
      subject: 'Prof de français',
      description:
        "Découvrez une nouvelle façon d'apprendre. Ensemble, nous allons transformer vos défis en réussites académiques",
      imgUrl: Hero4,
      note: '4.8'
    }
  ];

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  return (
    <Col>
      <div className="d-flex justify-content-center align-items-center flex-column w-100 mb-5">
        <div className="d-flex w-100 justify-content-between">
          <div className="hec-carousel-slider-teacher-pin">Les professeurs</div>
          <div className="hec-carousel-slider-teacher-controller">
            <Image
              src={leftArrow}
              onClick={handlePrev}
              width={13}
              height={26}
              style={{ cursor: 'pointer' }}
            />
            <Image
              src={rightArrow}
              onClick={handleNext}
              width={13}
              height={26}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center flex-column align-items-start w-100">
          <h3 className="hec-carousel-slider-teacher-title">
            Des professeurs de qualité, sélectionnés avec soin
          </h3>
          <h5 className="hec-carousel-slider-teacher-subtitle">
            Préparez-vous aux concours des grandes écoles de commerce avec notre formation
            d'excellence à prix abordables, où que vous soyez !
          </h5>
        </div>
      </div>
      <CarouselSlider
        ref={carouselRef}
        persona={persona}
        maxItemsToShow={4}
        renderItem={(persona) => (
          <TeacherPersona
            name={persona.name}
            description={persona.description}
            subject={persona.subject}
            imgUrl={persona.imgUrl}
            chipset={persona.chipset}
          />
        )}
      />
    </Col>
  );
};

export default CarouselSliderTeacher;
