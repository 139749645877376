import React, { useState } from 'react';
import Scheduler from 'components/features/Scheduler/Scheduler';
import { Button, Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './../MonCompte/MonCompte.css';
import SummaryList from 'components/features/SummaryList/SummaryList';
import CommingClassBand from './CommingClassBand';

const MyPlanning = ({ user }) => {
  const [activeCategory, setActiveCategory] = useState('Mes cours à venir');

  const handleCategoryClick = (categoryName) => {
    setActiveCategory(categoryName);
  };

  let contentComponent = null;

  switch (activeCategory) {
    case 'Mes cours à venir':
      contentComponent = <Scheduler user={user} />;
      break;
    case 'Historique des cours':
      contentComponent = <SummaryList user={user} />;
      break;
    default:
      contentComponent = <Scheduler user={user} />;
  }

  return (
    <Card id="card-container">
      <Card.Body className="card-body">
        <Row>
          <Col className="container">
            <div className="button-wrapper">
              <Button
                className={`card-button ${activeCategory === 'Mes cours à venir' ? 'active' : ''}`}
                onClick={() => handleCategoryClick('Mes cours à venir')}
              >
                Mes cours à venir
              </Button>
              <Button
                className={`card-button ${
                  activeCategory === 'Historique des cours' ? 'active' : ''
                }`}
                onClick={() => handleCategoryClick('Historique des cours')}
              >
                Historique des cours
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <CommingClassBand user={user} />
        </Row>
        <Row>{contentComponent}</Row>
      </Card.Body>
    </Card>
  );
};

MyPlanning.propTypes = {
  user: PropTypes.object.isRequired
};

export default MyPlanning;
