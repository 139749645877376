export const URL = 'https://monadev.fr';
export const API_URL = 'https://monadev.fr/api';
export const VIDEOCHAT_API_URL = 'https://monadev.fr/api_videochat';
export const VIDEO_SDK_API_URL = 'https://api.videosdk.live';
export const VIDEOCHAT_WEB_CLIENT_URL = 'https://monadev.fr/videochat';

// export const URL = 'http://localhost:3000';
// export const API_URL = 'http://localhost:5000';
// export const VIDEOCHAT_API_URL = 'http://localhost:9000';
// export const VIDEO_SDK_API_URL = 'https://api.videosdk.live';
// export const VIDEOCHAT_WEB_CLIENT_URL = 'http://localhost:3002';

export const ROLES = {
  STUDENT: 'Student',
  TEACHER: 'Teacher',
  ADMIN: 'Admin'
};

export const ROUTES = {
  HOME: '/',
  CHAT: '/chat',
  FAQS: '/faqs',
  PROFIL: '/profil',
  REVIEW: '/review',
  PRICING: '/offres',
  CONTACT: '/contact',
  LOGIN: '/connexion',
  PAGE_SUJET: '/sujet',
  MESSAGES: '/messages',
  DASHBOARD: '/accueil',
  SCHEDULER: '/scheduler',
  HEC: '/inscription_hec',
  ROOM: `${URL}/videochat/`,
  PAYMENT_SUCCESS: '/success',
  NOTRE_VISION: '/notre_vision',
  NOT_FOUND: '/page_introuvable',
  TEACH_LESSONS: '/teachlessons',
  ADMIN_GROUPS: '/gestion_groupes',
  UPDATE_CREDITS: '/update_credits',
  TEACHER_HISTORY: '/teacher_history',
  TEACHER_DASHBOARD: '/profdashboard',
  TEACHERS_LIST: '/liste_professeurs',
  ADMIN_USERS: '/gestion_utilisateurs',
  COMMENT_CA_MARCHE: '/comment_ca_marche',
  FORGOT_PASSWORD: '/mot_de_passe_oublie',
  INSCRIPTION_PROF: '/inscription_professeur'
};

export const SUJETS = {
  SI: 'SI',
  SVT: 'SVT',
  SES: 'SES',
  EMC: 'EMC',
  SNT: 'SNT',
  NSI: 'NSI',
  HLP: 'HLP',
  ESH: 'ESH',
  HGG: 'HGG',
  IPT: 'IPT',
  TIPE: 'TIPE',
  MATHS: 'Maths',
  HGGSP: 'HGGSP',
  LLCER: 'LLCER',
  CHIMIE: 'Chimie',
  ANGLAIS: 'Anglais',
  PHYSIQUE: 'Physique',
  FRANCAIS: 'Français',
  ESPAGNOL: 'Espagnol',
  ALLEMAND: 'Allemand',
  ECODROIT: 'Éco-droit',
  ENS: 'Ens. scientifique',
  MANAGEMENT: 'Management',
  OPTIONINFO: 'Option info',
  TECHNOLOGIE: 'Technologie',
  PHILOSOPHIE: 'Philosophie',
  INFORMATIQUE: 'Informatique',
  MATHAPPLI: 'Maths Appliquées',
  MATHAPPRO: 'Maths Approfondies',
  PHYSIQUE_CHIMIE: 'Physique-Chimie',
  SYNTHESETEXTE: 'Synthèse de textes',
  HISTOIRE_GEOGRAPHIE: 'Histoire-géographie'
};

export const NIVEAUX = {
  SIXIEME: 'Sixième',
  SECONDE: 'Seconde',
  PREMIERE: 'Première',
  CINQUIEME: 'Cinquième',
  QUATRIEME: 'Quatrième',
  TERMINALE: 'Terminale',
  TROISIEME: 'Troisième'
};

export const TYPES_COURS = {
  GROUPE: 'Cours en groupe',
  INDIVIDUEL: 'Cours instantané',
  PLANIFIER_SHORT: 'Planifier un cours',
  PLANIFIER: 'Planifier un cours individuel'
};

export const InscriptionSteps = {
  Formule: 1,
  Pack: 2,
  VousEtes: 3,
  Enfant: 4,
  Offre: 5,
  AgendaGroup: 6,
  AgendaPlanif: 7,
  Professeur: 8
};

export const SESSION_TYPES = {
  INSTANT: 'instant',
  PLANIF: 'planif',
  GROUPE: 'group',
  ADMIN: 'admin'
};

export const GROUP_TYPES = {
  PREPA_HEC: 'prpa_hec'
};

export const ROUTES_Reseaux = {
  FACEBOOK: 'https://www.facebook.com/profile.php?id=100090272066203',
  INSTAGRAM: 'https://www.instagram.com/mona_cours/'
};

export const FILE_TYPES = {
  PROFILE: 'profile',
  GROUP_TYPES: 'group_types',
  CERTIFICATE: 'certificate',
  CHAT_ATTACHMENT: 'chat_attachment'
};

export const SUBSCRIPTIONS = {
  COLLEGE: 'Collège',
  LYCEE: 'Lycée',
  PREPA: 'Prépa',
  TRIAL: 'Essai'
};

export const MonaID = '66b3d1d300808dda8799bb68';

export const STRIPE_PUBLIC_KEY =
  'pk_test_51OleHLK09wM3uXDH0ub4aJiduon7pNR20RHJHLbfLU1oxqIPBKCrhpBoPzHbQFOcSKEQ0TN9cijmZchgetUjLH6W00m46Y6d9J';
