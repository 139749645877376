import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import './CarouselSlider.css';

const CarouselSlider = forwardRef(({ persona, renderItem, maxItemsToShow = 4 }, ref) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(maxItemsToShow);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchDeltaX, setTouchDeltaX] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);

  useEffect(() => {
    const updateItemsToShow = () => {
      if (window.innerWidth < 1000) {
        setItemsToShow(1);
      } else if (window.innerWidth < 1300) {
        setItemsToShow(Math.min(2, maxItemsToShow));
      } else if (window.innerWidth < 1600) {
        setItemsToShow(Math.min(3, maxItemsToShow));
      } else {
        setItemsToShow(maxItemsToShow);
      }
    };

    window.addEventListener('resize', updateItemsToShow);
    updateItemsToShow();

    return () => window.removeEventListener('resize', updateItemsToShow);
  }, [maxItemsToShow]);

  const translateX =
    currentIndex * (100 / itemsToShow) * -1 + (touchDeltaX / window.innerWidth) * 100;

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
    setIsSwiping(true);
  };

  const handleTouchMove = (e) => {
    if (isSwiping) {
      const currentTouchX = e.touches[0].clientX;
      setTouchDeltaX(currentTouchX - touchStartX);
    }
  };

  const handleTouchEnd = () => {
    setIsSwiping(false);
    if (touchDeltaX > 50) {
      handlePrev();
    } else if (touchDeltaX < -50) {
      handleNext();
    }
    setTouchDeltaX(0);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < persona.length - itemsToShow ? prevIndex + 1 : prevIndex
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  useImperativeHandle(ref, () => ({
    next: handleNext,
    prev: handlePrev
  }));

  return (
    <div
      className="hec-carousel"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="w-100">
        <div className="hec-carousel-inner" style={{ transform: `translateX(${translateX}%)` }}>
          {persona.map((persona, index) => (
            <div
              key={index}
              className="hec-carousel-item"
              style={{ minWidth: `${100 / itemsToShow}%` }}
            >
              {renderItem ? renderItem(persona) : <h3>{persona.name}</h3>}
            </div>
          ))}
        </div>
      </div>

      {/* Indicateur de pagination */}
      <div className="carousel-indicator">
        {persona.slice(0, persona.length - itemsToShow + 1).map((_, index) => (
          <span key={index} className={`dot ${currentIndex === index ? 'active' : ''}`}></span>
        ))}
      </div>
    </div>
  );
});

CarouselSlider.displayName = 'CarouselSlider';

CarouselSlider.propTypes = {
  persona: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  renderItem: PropTypes.func,
  maxItemsToShow: PropTypes.number
};

export default CarouselSlider;
