import React from 'react';
import CardsController from './CardsController';
import './CardOverlay.css';
import FastPhoneInput from 'components/common/PhoneInput/FastPhoneInput';

const CardOverlay = () => {
  return (
    <div className="hec-card-overlay-wrapper" id="offer-card-overlay-section">
      <div className="hec-card-overlay-offer-pin">Nos offres</div>
      <h2 className="hec-card-overlay-title"> Des formules, adaptés à vos besoins</h2>
      <h4 className="hec-card-overlay-subtitle">
        Préparez-vous aux concours des grandes écoles de commerce avec notre formation d'excellence
        à prix abordables, où que vous soyez !
      </h4>
      <CardsController />
      <div className="hec-card-overlay-question">
        Tu as des questions ? Nous te rappelons gratuitement dans la journée !
      </div>
      <div className="hec-card-overlay-phone-input">
        <FastPhoneInput ButtonText="Me rappeler" ButtonColor="#312402" ButtonTextColor="#FFFFFF" />
      </div>
    </div>
  );
};

export default CardOverlay;
