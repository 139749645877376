/**
 * -----------------------------------------------------------------------------
 * This program belongs to Mona.
 * It is considered a trade secret, and is not to be divulged or used
 * by parties who have not received written authorization from the owner.
 * -----------------------------------------------------------------------------
 */

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import RouteGuard to protect certain routes
import RouteGuard from 'RouteGuard';

// Import various pages and features
import FAQs from 'components/pages/FAQs/FAQs';
import Chat from 'components/features/Chat/Chat';
import useCurrentUser from 'hooks/useCurrentUser';
import Review from 'components/features/Review/Review';
import NotFound from 'components/pages/NotFound/NotFound';
import Pricing from 'components/features/Pricing/Pricing';
import Dashboard from 'components/pages/Dashboard/Dashboard';
import Scheduler from 'components/features/Scheduler/Scheduler';
import Messages from 'components/features/Conversations/Messages';
import UserProfile from 'components/features/Profile/UserProfile';
import ContactForm from 'components/features/Contact/ContactForm';
import NotreVision from 'components/pages/NotreVision/NotreVision';
import Login from 'components/features/Authentification/Login/Login';
import UsersList from 'components/features/AdminPanel/Users/UsersList';
import TeachersList from 'components/features/TeachersList/TeachersList';
import TeachLessons from 'components/features/TeachLessons/TeachLessons';
import CommentCaMarche from 'components/pages/CommentCaMarche/CommentCaMarche';
import InscriptionHEC from 'components/features/InscriptionHEC/InscriptionHEC';
import SubjectPage from 'components/features/Subjects/SubjectPage/SubjectPage';
import UpdateCredits from 'components/features/AdminPanel/Users/UpdateCredits';
import PaymentSuccess from 'components/features/InscriptionForm/PaymentSuccess';
import TeacherDashboard from 'components/features/Dashboard/Teacher/TeacherDashboard';
import ForgotPassword from 'components/features/Authentification/Login/ForgotPassword';
import GroupsAdmin from 'components/features/AdminPanel/Groups/CreateGroups/GroupsAdmin';
import TeacherRegister from 'components/features/Authentification/Register/Teacher/TeacherRegister';
import TeacherHistory from 'components/features/AdminPanel/Users/TeacherHistory';

// Import common components
import CustomModal from 'components/common/CustomModal/CustomModal';

// Import utilities
import { getID } from 'utils/getID';
import { changeAvailability } from 'api/Teacher/teacherApi';

// Import contexts or context providers
import { SelectionProvider } from 'hooks/SelectionProvider';

// Import constants
import { ROUTES, ROLES } from 'Env';

const App = () => {
  const { user } = useCurrentUser();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (user && user.role === ROLES.TEACHER) {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/worker.js');
      }

      const worker = new Worker('/worker.js');

      worker.postMessage('start');

      worker.onmessage = (e) => {
        if (e.data === 'checkAvailability') {
          if (Notification.permission === 'granted') {
            showNotification();
          } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
              if (permission === 'granted') {
                showNotification();
              }
            });
          }
          setShowModal(true);
        }
      };

      return () => {
        worker.postMessage('stop');
        worker.terminate();
      };
    }
  }, [user]);

  const showNotification = () => {
    const notification = new Notification('Êtes-vous toujours prêt à donner un cours ?', {
      body: 'Sans réponse, dans 10 minutes vous serez déconnecté.',
      icon: '/icon.png'
    });

    notification.onclick = () => {
      changeAvailability(getID(user), true);
      setShowModal(false);
    };

    // Automatically set the teacher to unavailable if no response in 10 minutes
    setTimeout(() => {
      notification.close();
      changeAvailability(getID(user), false);
      setShowModal(false);
    }, 600000); // 10 minutes in milliseconds
  };

  const handleModalSubmit = () => {
    changeAvailability(getID(user), true);
    setShowModal(false);
  };

  const handleModalCancel = () => {
    changeAvailability(getID(user), false);
    setShowModal(false);
  };

  return (
    <SelectionProvider>
      <Router>
        <Routes>
          {/* Routes accessible to both logged in and logged out users (with layout)*/}
          <Route path="/" element={<RouteGuard allowLoggedOut showMenu />}>
            <Route path={ROUTES.FAQS} element={<FAQs />} />
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.PRICING} element={<Pricing />} />
            <Route path={ROUTES.HEC} element={<InscriptionHEC />} />
            <Route path={ROUTES.PROFIL} element={<UserProfile />} />
            <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
            <Route path={ROUTES.CONTACT} element={<ContactForm />} />
            <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
            <Route exact path={ROUTES.HOME} element={<Dashboard />} />
            <Route path={ROUTES.PAGE_SUJET} element={<SubjectPage />} />
            <Route path={ROUTES.TEACHERS_LIST} element={<TeachersList />} />
            <Route path={ROUTES.TEACH_LESSONS} element={<TeachLessons />} />
            <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route exact path={ROUTES.NOTRE_VISION} element={<NotreVision />} />
            <Route path={ROUTES.INSCRIPTION_PROF} element={<TeacherRegister />} />
            <Route path={ROUTES.TEACHER_DASHBOARD} element={<TeacherDashboard />} />
            <Route exact path={ROUTES.COMMENT_CA_MARCHE} element={<CommentCaMarche />} />
          </Route>
          {/* Routes accessible to both logged in and logged out users (without layout)*/}
          <Route path="/" element={<RouteGuard allowLoggedOut />}></Route>
          {/* Routes accessible to logged in users */}
          <Route path="/" element={<RouteGuard showMenu />}>
            <Route path={ROUTES.CHAT} element={<Chat />} />
            <Route path={ROUTES.REVIEW} element={<Review />} />
            <Route path={ROUTES.MESSAGES} element={<Messages />} />
            <Route path={ROUTES.SCHEDULER} element={<Scheduler />} />
            <Route path={ROUTES.ADMIN_USERS} element={<UsersList />} />
            {/* <Route path={ROUTES.ADMIN_GROUPS} element={<GroupsList />} /> */}
            <Route path={ROUTES.ADMIN_GROUPS} element={<GroupsAdmin />} />
            <Route path={ROUTES.PAYMENT_SUCCESS} element={<PaymentSuccess />} />
            <Route path={ROUTES.UPDATE_CREDITS} element={<UpdateCredits />} />
            <Route path={ROUTES.TEACHER_HISTORY} element={<TeacherHistory />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <CustomModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title="Êtes-vous toujours prêt à donner un cours ?"
        submitButton={{ text: 'Je suis prêt', submit: handleModalSubmit }}
        cancelButton={{ text: 'Me remettre hors ligne', submit: handleModalCancel }}
        isPrompt={false}
      >
        <p>Sans réponse, dans 10 minutes vous serez déconnecté.</p>
      </CustomModal>
    </SelectionProvider>
  );
};

export default App;
