import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Container, Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Switch } from '@mui/material';

import SearchEvents from './SearchEvents';
import { getTeacherEarnings } from 'api/Teacher/teacherApi';

const EventsList = () => {
  const location = useLocation();
  const [teacherID, setTeacherID] = useState(null);
  const [events] = useState([
    {
      _id: '6703b8f286b39032770e30ea',
      title: 'Math Trial Lesson',
      start: '2024-10-10T09:00:00.000Z',
      end: '2024-10-10T10:00:00.000Z',
      recurring: false,
      users: ['66eac0a549afacb7cd8d8318', '66e00cc06fba183021d4b13c'],
      level: { label: 'Terminale', value: 'terminale' },
      subject: { label: 'Maths', value: 'maths' },
      teacherComment: 'Focus on simplifying expressions.'
    },
    {
      _id: '6703b8f286b39032770e30eb',
      title: 'Physics Class',
      start: '2024-10-12T09:00:00.000Z',
      end: '2024-10-12T10:30:00.000Z',
      recurring: false,
      users: ['66eac0a549afacb7cd8d8318', '66e00cc06fba183021d4b13c'],
      level: { label: 'Première', value: 'premiere' },
      subject: { label: 'Physique', value: 'physique' },
      teacherComment: 'Discuss laws of thermodynamics.'
    }
  ]);
  const [, setSelectedEvents] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);

  const columns = [
    {
      id: 1,
      name: 'Titre',
      selector: (row) => row.title,
      sortable: true,
      reorder: true
    },
    {
      id: 2,
      name: 'Niveau',
      selector: (row) => row.level.label,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: 'Matière',
      selector: (row) => row.subject.label,
      sortable: true,
      reorder: true
    },
    {
      id: 4,
      name: 'Date de début',
      selector: (row) => new Date(row.start).toLocaleDateString('fr-FR'),
      sortable: true,
      reorder: true
    },
    {
      id: 5,
      name: 'Durée (minutes)',
      selector: (row) => {
        const start = new Date(row.start);
        const end = new Date(row.end);
        return (end - start) / (1000 * 60);
      },
      sortable: true,
      reorder: true
    },
    {
      id: 6,
      name: 'Payé',
      selector: (row) => (
        <Switch checked={row.paid} onChange={() => handleTogglePaid(row._id)} color="primary" />
      ),
      sortable: false,
      reorder: false
    }
  ];

  const fetchSingleTeacherEarnings = async (teacherID) => {
    let total = 0;

    try {
      const earnings = await getTeacherEarnings(teacherID);
      total += earnings.teacherEarnings || 0; // Ensure earnings is a number
    } catch (error) {
      console.error('Failed to fetch teacher earnings:', error);
      total = 0;
    }

    setTotalEarnings(total);
    return total;
  };

  const handleTogglePaid = (eventID) => {
    console.log('Toggling paid status for event:', eventID);
  };

  useEffect(() => {
    if (location.state && location.state.teacherID) {
      setTeacherID(location.state.teacherID);
    }

    if (teacherID) {
      fetchSingleTeacherEarnings(teacherID).then((total) => {
        setTotalEarnings(total);
      });
    }
  }, [events, location.state]);

  return (
    <Container>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Historique des cours</Card.Title>
              <br />
              <Row>
                <Col>
                  <SearchEvents
                    columns={columns}
                    data={events}
                    setSelectedEvents={setSelectedEvents}
                  />
                </Col>
              </Row>
              <br />
              <Alert variant="info" className="text-center">
                <strong>Total dû aux enseignants :</strong>{' '}
                {Number(totalEarnings).toLocaleString('fr-FR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}{' '}
                €
              </Alert>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EventsList;
