import React from 'react';
import PropTypes from 'prop-types';
import './FastPhoneInput.css';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';
import { API_URL } from 'Env';

const FastPhoneInput = ({
  PhoneNumber = 'Entrez votre numéro de téléphone',
  ButtonText = 'En savoir plus',
  ButtonColor = '#007bff', // Valeur par défaut pour la couleur du bouton
  ButtonTextColor = '#ffffff' // Valeur par défaut pour la couleur du texte
}) => {
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!PhoneNumber.match(/^\+?[0-9]\d{1,14}$/)) {
      return;
    }

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        phone: PhoneNumber
      })
    };

    fetchWithAuthorization(API_URL + '/api/email/send-phone', requestOptions).catch((error) => {
      console.error('Erreur:', error);
    });
  };

  return (
    <InputGroup>
      <FormControl
        id="fast-phone-input-formcontrol"
        type="tel"
        placeholder={PhoneNumber}
        pattern="[0-9]*"
        required
      />
      <Button
        id="fast-phone-input-btn"
        onClick={handleSubmit}
        style={{
          backgroundColor: ButtonColor,
          borderColor: ButtonColor,
          color: ButtonTextColor // Appliquer la couleur du texte
        }}
      >
        {ButtonText}
      </Button>
    </InputGroup>
  );
};

FastPhoneInput.propTypes = {
  PhoneNumber: PropTypes.string,
  ButtonText: PropTypes.string,
  ButtonColor: PropTypes.string, // Prop pour la couleur du bouton
  ButtonTextColor: PropTypes.string // Prop pour la couleur du texte
};

export default FastPhoneInput;
