import React, { useState, useEffect } from 'react';
import Spinner from 'components/common/Spinner/Spinner';
import TeacherProfileCard from './TeacherProfileCard';
import TeachersListOptions from './TeachersListOptions/TeachersListOptions';
import { getID } from 'utils/getID';
import { useLocation } from 'react-router-dom';
import { TYPES_COURS } from 'Env';
import { getRandomSubjectFromTeacher } from './getRandomSubjectFromTeacher';
import { API_URL } from 'Env';
import './TeachersList.css';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';
import TeachersListConditionnalDisplayer from './TeachersListConditionnalDisplayer';

const TeachersList = () => {
  const location = useLocation();

  const [isSelecting] = useState(location.state ? location.state.isSelecting : false);
  const [loading, setLoading] = useState(true);

  const [teacherProfileCards, setTeacherProfileCards] = useState([]);

  const [type, setType] = useState(TYPES_COURS.INDIVIDUEL);
  const [level, setLevel] = useState(location.state ? location.state.level : null);
  const [subject, setSubject] = useState(location.state ? location.state.subject : null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const url = new URL(`${API_URL}/api/teachers`);

      console.log('S!', subject);
      if (subject && subject.value) {
        console.log('Subject value:', subject.value);
        url.searchParams.append('subject', subject.value);
      } else {
        console.log('Subject is not defined or has no value');
      }

      if (level && level.value) {
        console.log('Level value:', level.value);
        url.searchParams.append('level', level.value);
      } else {
        console.log('Level is not defined or has no value');
      }

      try {
        const response = await fetchWithAuthorization(url);
        const data = await response.json();
        console.log('Data:', data);
        const teachers = filterTeachersByLevelAndSubject(data);
        const cards = await getTeacherProfileCards(teachers);
        setTeacherProfileCards(cards);
      } catch (error) {
        console.error('Error fetching teachers:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [subject, level]);

  const filterTeachersByLevelAndSubject = (teachers) => {
    return teachers.filter((teacher) => {
      // Vérifiez si l'enseignant est validé et visible
      const isValidAndVisible =
        teacher.teacherDetails.validated === true && teacher.teacherDetails.isInvisible === false;

      // Si l'enseignant n'est pas validé ou est invisible, excluez-le
      if (!isValidAndVisible) {
        return false;
      }

      // Filtre selon le niveau et la matière
      const teachesLevel = level
        ? teacher.teacherDetails.levels.some((niveau) => niveau.value === level.value)
        : true;

      const teachesSubject = subject
        ? teacher.teacherDetails.subjects.some((matiere) => matiere.value === subject.value)
        : true;

      return teachesLevel && teachesSubject;
    });
  };

  async function getTeacherProfileCards(teachers) {
    const promises = teachers.map((teacher) => (
      <TeacherProfileCard
        subject={subject || getRandomSubjectFromTeacher(teacher)}
        teacher={teacher}
        key={getID(teacher)}
        isSelecting={isSelecting}
      />
    ));
    return Promise.all(promises);
  }

  if (loading) return <Spinner />;
  const onlineProf = teacherProfileCards.filter(
    (card) => card.props.teacher.teacherDetails.isAvailable === true
  ).length;
  const offlineProf = teacherProfileCards.filter(
    (card) => card.props.teacher.teacherDetails.isAvailable === false
  ).length;

  return (
    <div>
      <TeachersListOptions
        subject={subject}
        setSubject={setSubject}
        level={level}
        setLevel={setLevel}
        type={type}
        setType={setType}
      />
      <TeachersListConditionnalDisplayer
        type={type}
        onlineProf={onlineProf}
        offlineProf={offlineProf}
        teacherProfileCards={teacherProfileCards}
      />
    </div>
  );
};

export default TeachersList;
